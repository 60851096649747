import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Vols posar-te a prova amb una simulació d'entrevista?</strong>
                </p>

                <p>
                    <strong>Recrearem el mateix escenari que el dia de la prova oficial.</strong>
                </p>

                <p>
                    La nostra preparació és molt personalitzada, amb preguntes extretes de processos
                    policials actuals.
                </p>

                <p>
                    Això fa que el nostre mètode sigui únic i el nostre percentatge d'aprovats sigui
                    altíssim.
                </p>

                <p>
                    <strong>En una entrevista policial estaràs nerviós, assumeix-ho.</strong>
                </p>

                <p>
                    Des de <strong>iOpos</strong> t'ensenyem a minimitzar l'ansietat anticipatòria.
                </p>

                <p>
                    És un procés normal, adaptatiu. Una bona preparació per part del nostre equip
                    t'ajudarà molt.
                </p>

                <p>
                    Treballarem tots els aspectes clau que necessitaràs per afrontar-la de la millor
                    manera possible.
                </p>

                <p>
                    <strong>La metodologia és del tot personalitzada</strong> i es posarà èmfasi en
                    les preguntes-respostes més importants d'acord amb cada alumne.
                </p>

                <p>
                    En definitiva, t'ajudarem a treballar la teva entrevista personal des d'un punt
                    de vista honest i natural.
                </p>

                <p>
                    <strong>La preparació consisteix en 1 hora cara a cara</strong> amb un
                    preparador especialitzat que t'analitzarà i et guiarà de la millor manera.
                </p>

                <p>
                    <strong>45 minuts de simulacre i 15 minuts de feedback final.</strong>
                </p>

                <p>
                    <strong>Escull dia i hora... T'esperem!</strong>
                </p>
                <div id="disponibles"></div>

                <div className="mt-10 border-t border-b border-gray-300 pt-5 pb-10">
                    <h1 className="uppercase font-bold text-cpurple text-lg">
                        Simulacres disponibles per formador i ubicació
                    </h1>
                    <div id="disponibles" />

                    <div className="grid grid-cols-1 gap-10 mt-10">
                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Jordi (Psicòleg) C/ Martí Codolar, núm. 18, Hospitalet de
                                Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-jordi/simulacre-d-entrevista-policial-1-00-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-jordi/simulacre-d-entrevista-policial-1-00-h?hide_gdpr_banner=1"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-10 mt-10">
                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Txema (Psicòleg) C/ Martí Codolar, núm. 18, Hospitalet de
                                Llobregat
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-jose-m/simulacre-entrevista-policial-1-00-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-jose-m/simulacre-entrevista-policial-1-00-h?hide_gdpr_banner=1"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-10 mt-10">
                        <div className="rounded-md shadow-xl p-5 border border-gray-400">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Rubén (Psicòleg) C/ de les Travesseres, 43, 08401
                                Granollers, Barcelona (COWORKING)
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat mt-5">
                                Si no veus correctament el calendari fes clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-ruben/simulacre-d-entrevista-policial-1-00-h"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-ruben/simulacre-d-entrevista-policial-1-00-h?hide_gdpr_banner=1"
                            />
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/lH_fdXopHX0"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't amb els millors i afronta la teva entrevista amb seguretat. Reserva el teu simulacre ara!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
